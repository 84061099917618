import {postUpdateAssetsActionOrComment} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";

export const tableHeader = {
  "Asset Name": {"field": "asset_name", "header": "Asset Name", "sort": true, "defaultSortValue": false},
  "Status": {"field": "status", "header": "STATUS", "sort": true, "defaultSortValue": false},
  "Type": {"field": "os_platform_name", "header": "TYPE", "sort": true, "defaultSortValue": false},
  "Vulnerabilities": {
    "field": "vulnerability_count",
    "header": "VULNERABILITIES",
    "sort": true,
    "defaultSortValue": false
  },
  "Missing Patches": {
    "field": "missing_patch_count",
    "header": "MISSING PATCHES",
    "sort": true,
    "defaultSortValue": false
  }
}

export const filterConfig = [
  {
    'OS': {
      filter_name: "os_type",
      isOpen: true,
      filters: {'Linux': false, 'Windows': false, 'Mac': false, 'iOS': false, 'Android': false}
    },
  },
  {
    Device_Type: {
      filter_name: "device_type",
      isOpen: true,
      filters: {'Endpoint': false, 'Other': false},
      title: 'Device Type'
    }
  }, {
    'Action Status': {
      filter_name: "action",
      isOpen: true,
      filters: {
        'New Risk': false,
        'Accept Risk': false,
        'Defer Risk': false,
        'Ignore': false,
        'In-Progress': false,
      }
    }
  }
]

export const resetFilterConfig = {
  Exploit: {
    filter_name: "exploit",
    isOpen: true,
    filters: {'Yes': false, 'No': false}
  },
  'OS': {
    filter_name: "os_type",
    isOpen: true,
    filters: {'Linux': false, 'Windows': false, 'Mac': false, 'iOS': false, 'Android': false}
  },
  Device_Type: {
    filter_name: "device_type",
    isOpen: true,
    filters: {'Endpoint': false, 'Other': false},
    title: 'Device Type'
  },
  'Action Status': {
    filter_name: "action",
    isOpen: true,
    filters: {
      'New Risk': false,
      'Accept Risk': false,
      'Defer Risk': false,
      'Ignore': false,
      'In-Progress': false,
    }
  }
}

export const tabs = [
  {display: `VULNERABILITIES`, configName: "vulnerabilities"},
  {display: `PATCHES`, configName: "patches"}
];

export const vulnerabilitiesTableData = {
  vulnerabilities: {
    headers: {
      'vulnerability_id': 'vulnerability_id',
      'description': 'description',
      'severity': 'severity',
      action_taken: 'action_taken',
      comment: ''
    },
  },
  patches: {
    headers: {
      'title': 'title',
      'description': 'description',
      'severity': 'severity'
    }
  }
};

export const actionOptions = [
  {'Accept Risk': false},
  {'Defer Risk': false},
  {'In-Progress': false},
  {'Ignore': false},
  {"": ""},
  {"Add Comments": false},
  {"Edit Comments": false}
]

export const rowActionOptions = [
  {'Accept Risk': false},
  {'Defer Risk': false},
  {'In-Progress': false},
  {'Ignore': false}
]

export const actionTooltipConfig = {
  'Accept Risk': 'Accept Vulnerability Risk',
  'Ignore': 'Customer Opt-Out: Ignoring Vulnerability',
  'Defer Risk': 'Deferring Fix: Accepting Vulnerability Risk',
  'In-Progress': 'Fixing Vulnerability',
  'Risk Mitigated': "Automatically Marked as Risk Mitigated. Updated when they are not found during subsequent scans. This process is automated and doesn't consider factors like offline assets or unscanned endpoints.",
  'New Risk': 'Automatically Marked as New Vulnerability'
}

export const tables = [
  {
    labels: ['Asset Name', 'Hostname', 'Domain', 'IP Address', 'DNS Name', 'NetBIOS Name', 'OS Platform Name', 'First Discovered', 'Last Observed'],
    title: 'Asset Info'
  },
  {
    labels: ['Status', 'OS Version', 'MAC Address', 'Confidence', 'Firewall Enabled', 'EDR Type', 'EDR Version', 'Bitlocker Protection', 'Encryption', 'Port', 'Protocol'],
    title: 'Details'
  },
];

export const updateAsset = async (value, rowIndex, selectedCheckboxes, selectActionFromDropdown, vuln_id, userData, setIsLoading, comment) => {
  setIsLoading(true);
  let body = {vulnerability_ids: selectedCheckboxes, ...(comment?.length > 0 && { comment })}
  if (!value.includes('Comments')) body.action = value
  try {
    await postUpdateAssetsActionOrComment(userData?.tenant, vuln_id, body);
  } catch (e) {
    captureSentryError(e, userData, "postUpdateAssetsActionOrComment action/comment update assets tab");
  } finally {
    setIsLoading(false);
    selectActionFromDropdown(rowIndex, value);
  }
};
